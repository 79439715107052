@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

//colors
$base_background: #282c34;
$base_text: #fff;
$for_include_bg: rgba(64, 224, 208, 0.5);
$input_bg: rgba(238, 130, 238, 0.5);

body {
    background-color: $base_background;
    color: $base_text;
    font-family: 'Roboto', sans-serif;
}

.App {
    .App-header {
        margin-top: 50px;
        text-align: left;
        border-bottom: 1px solid white;
    }
}

.pseudo_form_container {
    
    position: relative;
    margin-top: 30px;

    div.frm_obj {
        position: absolute;
        display:block;
        cursor: pointer;
        z-index: 1;
        span.label {
            font-size: 0.8em;
            display: block;
            overflow: hidden;
            height: 100%;
        }
        span.obj_data {
            font-size: 0.5rem;
            display: block;
            background-color: #000;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            position: absolute;
            top: -5px;
            left: -5px;
        }    

        &.window {
            border: 1px dashed #fff;
        }

        &.push_button {
            background: rgba(255,255,255,0.3);
        }

        &.check_box {
            background: rgba(70,170,255,0.3);

            &:before {
                content: '';
                display: block;
                width: 7px;
                height: 7px;
                border: 1px solid white;
                background: rgba(255,255,255,0.5);
                position: absolute;
                left: 20px;
                top: 5px;
                opacity: 0.5;
            }
        }

        &.combo_box {
            background: rgba(170,100,255,0.3);
            &:after {
                content: 'ˇ';
                display: block;
                width: 7px;
                height: 7px;
                position: absolute;
                right: 5px;
                top: 5px;
                opacity: 0.5;
                font-weight: bold;
            }
        }

        &.image {
            background: rgba(100,100,255,0.3);
        }

        &.static {
           font-weight: 300; 
           font-size: 0.7rem;
           text-align: center;

           span.obj_data {
               display: block;
               border: 1px dotted rgba(0,0,0,0.4);
           }

           &:hover {
               span.obj_data{
                   display: block;
               }
           }
        }

        &.subform {
            background: $for_include_bg;

        }

        &.table {
            background: $for_include_bg;
        }

        &.edit {
            background: $input_bg;
        }

        &.draggable {
            z-index: 1000;
            &:hover {
                border: 1px solid orange;
            }
        }

        &.dropable {
            z-index: 500;
        }

        &.dragover {
            border: 1px solid greenyellow;
            span.obj_data {
                background-color: greenyellow;
                color: #000;
            }
        }
    }

    @mixin htmlType ($background) {
        span.htmlType {
            position: absolute;
            right: -50px;
            width: 50px;
            top: 0;
            display: block;
            background-color: $background;
            font-size: 0.6em;
            line-height:20px;
            text-align: center;

            &:hover {
                background-color: sienna;
                cursor: pointer;
            }
        }
    }

    @mixin htmlTypeVertical ($background) {
        span.htmlType {
            position: absolute;
            right: -10px;
            width: 40px;
            top: 10px;
            display: block;
            background-color: $background;
            font-size: 0.6em;
            line-height:20px;
            text-align: center;
            transform: rotate(90deg);

            &:hover {
                background-color: sienna;
                cursor: pointer;
            }
        }
    }

    @mixin structureElementInSelection() {
        &.in_selection {
            .dropZone {
                display: none;
            }

            .htmlType {
                display: none;
            }

            > .htmlType {
                display: block;
                background-color: orange;
            }

            .addElement {
                display: none;
            }
        }
    }

    @mixin rowDropZones() {
        .dropZone {
            display: block;
            position: absolute;
            width:100%;
            height:20px;
            background:  rgba(255,255,255,0.5);
            color: #000;
            z-index: 999;
            text-align: center;
            left: 0;
            /*transform: translateX(-50%);*/
            font-size: 10px;

            &.up {
                top: 0px;
            }

            &.down {
                bottom: 0px;
            }

            &.dragover {
                background-color: seagreen;
                display: block;
            }
        }

        &.dragover {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    @mixin columnDropZones() {
        @include rowDropZones();

        .dropZone {
            width: 20%;
            height: 100%;

            &.up {
                top: 0px;
                left: 0px;
                right: auto;
            }

            &.down {
                top: 0px;
                right: 0px;
                left: auto;
            }

            &.in {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

    }

    @mixin structureElementsAddButton() {
        .addElement {
            display: none;
            position: absolute;
            width:12px;
            height:12px;
            background:  rgba(255,255,255,0.5);
            color: #000;
            z-index: 999;
            text-align: center;
            right: 120px;
            top: 0px;
            font-size: 10px;
            cursor: pointer;

            &:hover {
                background-color: orange;
            }
        }
    }

    @mixin structureElementBasics() {
        .separator {
            float: none;
            clear: both;
            width: 100%;
        }

        div.frm_obj {
            position: initial !important;
            top: 0 !important;
            left: 0 !important;
            display: block !important;
            float: left;
            margin: 3px;
            min-width: 50px;
            z-index: auto;
            overflow: hidden;
        }

    }

    div.inputw {
        position: relative;
        margin-bottom: 40px;
    }

    div.window {
        border: 1px dotted rgba(238, 130, 238, 0.5);
        padding: 20px 10px;
        position: relative;
        width: 90%;

        @include htmlType(rgba(238, 130, 238, 0.5));
    }

    div.inputw {
        border: 1px dotted rgba(238, 130, 238, 0.5);
        padding: 20px 10px;
        position: relative;
        width: 90%;

        @include htmlType(rgba(238, 130, 238, 0.5));
    }

    div.row {
        display: block;
        padding: 4px 25px 2px 0px;
        border: 1px solid #fff;
        background-color: rgba(255,255,255,0.1);
        float: none;
        clear: both;
        position: relative;
        z-index: auto;
        min-height: 70px;
        margin-bottom: 10px;

        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        &.dragover {
            border-color: seagreen;
        }
        
        @include htmlType(rgba(255,255,255,0.5));

        @include structureElementBasics();

        @include rowDropZones();

        @include structureElementInSelection();

        @include structureElementsAddButton();

    }

    div.column {
        display:block;
        float: left;
        position: relative;
        border: 1px dotted #fff;
        background-color: rgba(255,255,255,0.1);
        min-height: 70px;
        z-index: auto;
        padding: 20px 20px;

        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        &.dragover {
            border-color: seagreen;
        }

        @include htmlTypeVertical(rgba(255,255,255,0.5));

        @include structureElementBasics();

        @include columnDropZones();

        @include structureElementInSelection();

        @include structureElementsAddButton();
    }

    .in_selection {
        border: 2px dotted orange !important;
    }

    @mixin siblingsWidth($siblings) {
        $width: 100%;
        width: $width / $siblings;
    }

    @for $i from 1 through  24 {
        div.sc-#{$i} {
             @include siblingsWidth($i);
        }
    }
}

#exportBox {
    position: fixed;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    color: #fff;
    background: rgba(0,0,0,0.6);
    z-index: 9999;

    &::before {
        content: '>';
        position: absolute;
        top: 30px;
        left: -30px;
        width: 30px;
        line-height: 30px;
        height: 30px;
        text-align: center;
        font-size: 2em;
        background: rgba(0,0,0,0.6);
        cursor: pointer;
    }
    textarea {
        background: transparent;
        display: block;
        width: 100%;
        height: 100%;
        color: #fff;
        border: 0 !important;
        padding: 30px;

        &:focus {
            border: 0 !important;
        }
    }

    &.hidden {
        width: 0px;
        /*textarea {
            display: none;
        }*/

        &::before {
            content: '<';
        }
    }
}

.buttonContainer {
    display: inline;
}

.modalHolder {
    position: absolute;
    z-index: 9996;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9997;
    text-align: center;

    .cover {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.8);
        z-index: 9998;
    }

    .wrapper {
        position: relative;
        text-align: left;
        width: 50%;
        min-height: 200px;
        background: white;
        color: #000;
        margin: 40px auto auto auto;
        z-index: 9999;
        cursor: auto;

        .header {
            padding: 15px;
            border-bottom: 1px solid #282c34;
            background: #282c34;
            color: white;
            position: relative;

            a {
                color: rgba(255,255,255,0.5);
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                text-decoration: none;

                &:hover {
                    color: rgba(255,255,255,1);
                }
            }
        }

        .body {
            padding: 15px;

            > div {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}

.input_group {
    border: 1px dotted #61dafb;
    display: inline-block;

}

.menuBar {
    z-index: 9000;
    position: fixed;
    top: 0;
    left: 0;
    background: #282c34;
    width: 100%;

    -webkit-box-shadow: 5px 5px 15px 5px #21242B;
    box-shadow: 5px 5px 15px 5px #21242B;

    .menu {
        padding: 10px;
    }
}

form {
    font-size: 15px;
    display: block;
    float: none;
    clear: both;
    padding-bottom: 40px;
    font-weight: normal;
    width: 100%;

    .form-group {
        display: block;
        padding: 5px 0px 5px 0px;
        margin-bottom: 5px;
        line-height: 20px;
        height: 30px;
        float: none;
        clear: both;
        width: 100%;

        label {
            display: block;
            float: left;
            line-height: 30px;
            width: 100px;
        }

        .form-control {
            font-size: 14px;
            line-height: 30px;
            height: 20px;
            float: left;
            padding: 4px 8px 4px 8px;
            width: 98%;
            border: 1px solid #dfdfdf;
            color: #282c34;
        }

        select.form-control {
            height: 30px;
        }

        button {
            margin-top: 10px;
            float: right;
            padding: 10px;
            background: #282c34;
            color: white;
            border: 0;
        }
    }
}